import React from 'react'
import { Link } from 'gatsby'

const TopHeader = () => {
  return (
    <div id="home" className="top-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-10">
            <div className="address-bar">
              <ul className="list-inline">
                <li>
                  <Link to="#">
                    <i className="fa fa-envelope"></i>{' '}
                    contact@talinnaminizootogo.com 
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-volume-control-phone"></i> +228 98 47 87 87 / 91 64 33 33
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-2">
            <div className="social-icons">
              <ul className="list-inline">
                <li>
                  <Link to="#">
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-google-plus"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopHeader
