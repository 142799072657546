import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import './assets/animate.css'
import './assets/bootstrap.min.css'
import './assets/flaticon.css'
import './assets/font-awesome.min.css'
import 'react-image-lightbox/style.css'
import 'react-modal-video/css/modal-video.min.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import './assets/style.css'
import './assets/responsive.css'
import './assets/default-color.css'
import Navigationbar from './Navigationbar'
import BlogNavigationbar from './BlogNavigationbar'
import Footer from './Footer'
import GoTop from './GoTop'
import Preloader from './Preloader'
import config from '../../../config'

const Layout = ({ children, location }) => {
  const [loader, setLoader] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1000)
  }, [])

  return (
    <React.Fragment>
      <Helmet title={config.siteTitle} defer={false} />
      {loader ? <Preloader /> : null}
      {location === 'blog' ? <BlogNavigationbar /> : <Navigationbar />}
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

export default Layout
