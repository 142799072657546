module.exports = {
    siteTitle: `TALI N'NA HÔTEL & MINI ZOO PARC - TOGO`, // <title>
    siteDescription: `TALI N'NA HÔTEL Mini Zoo Parc - TOGO`,
    manifestName: `Site web du Zoo TALI N'NA`,
    manifestShortName: `TALI N'NA MINI ZOO`, // max 12 characters
    manifestStartUrl: '/',
    manifestBackgroundColor: '#fe6519',
    manifestThemeColor: '#fe6519',
    manifestDisplay: 'standalone',
    manifestIcon: 'src/images/icon.png',
    authorName: 'Wilson KOMLAN',
    heading: 'Full stack Developer',
    // social
    socialLinks: [
      {
        icon: 'fa-twitter',
        name: 'Twitter',
        url: 'https://twitter.com/KomlanWilson',
      },
      {
        icon: 'fa-linkedin',
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/wilkom2009',
      },
      {
        icon: 'fa-facebook',
        name: 'Facebook',
        url: 'https://facebook.com/wilkom2009',
      },
      {
        icon: 'fa-envelope-o',
        name: 'Email',
        url: 'mailto:koffikomlan01@gmail.com',
      },
      {
        icon: 'fa-github',
        name: 'Github',
        url: 'https://github.com/wilkom2009',
      },
    ],
  };